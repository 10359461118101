import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Benefits of VASCEPA® (icosapent ethyl)"
      description="Discover how FDA-approved prescription VASCEPA® (icosapent ethyl) capsules can help protect your heart. Please see Indication and Important Safety Information."
      />
    <h1>Page 2.0</h1>
    <ul>
    	<li><Link to="/page-2.1">2.1</Link></li>
    	<li><Link to="/page-2.2">2.2</Link></li>
    </ul>


  </Layout>
)
